import CheckCircleIcon from "../../src/assets/images/check-circle.svg";
import FastIcon from "../../src/assets/images/fast-icon.svg";
import ThunderboltOutlined  from "../../src/assets/images/thunderbolt-outlined.svg";
import TransactionOutlined from "../../src/assets/images/transaction-outlined.svg";
import DollarSign from "../../src/assets/images/dollar-sign.svg";
import PropertySafetyOutlined from "../../src/assets/images/property-safety-outlined.svg";
import MoneyCollect from "../../src/assets/images/money-collect.svg";

import { IntlShape } from "react-intl";

export const getBenefitsContent = (intl: IntlShape) => [
    { 
        imageSrc: ThunderboltOutlined,         
        title: intl.formatMessage({ id: "SIMPLE_UI" }),    
        description: intl.formatMessage({ id: "SIMPLE_UI_DESC" }) 
    },
    { 
        imageSrc: TransactionOutlined,    
        title: intl.formatMessage({ id: "ONE_TIME_SETUP" }),      
        description: intl.formatMessage({ id: "ONE_TIME_SETUP_DESC" }) 
    },
    { 
        imageSrc: DollarSign,        
        title: intl.formatMessage({ id: "INCREASE_PRODUCTIVITY_AND_EFFICIENCY" }),    
        description: intl.formatMessage({ id: "INCREASE_PRODUCTIVITY_AND_EFFICIENCY_DESC" }) 
    },
    { 
        imageSrc: PropertySafetyOutlined,          
        title: intl.formatMessage({ id: "GOVERNMENT_COMPLIANT" }),   
        description: intl.formatMessage({ id: "GOVERNMENT_COMPLIANT_DESC" }) 
    },
    {
        imageSrc: MoneyCollect,
        title: intl.formatMessage({id: "FLEXIBLE_PAYMENT_METHODS"}),
        description: intl.formatMessage({id: "FLEXIBLE_PAYMENT_METHODS_DESC"})
    },

];
