import React from 'react';
import { useIntl } from 'react-intl';
import { loginUrl } from "../../content/HomeContent";
import appLogo from '../../assets/images/app-logo.png';
import LanguageSwitcher from '../language-switcher/LanguageSwitcher';
import './Header.less';

const Header = ({ isBlack, isSubPage, refHero }: any) => {
  const intl = useIntl();
  return (
    <header className={`transparent-header ${isBlack ? 'blacked' : ''}`}>
      <nav className="nav-container">
        <a className="header-logo-link" href={isSubPage ? "/" : "#Home"}>
          <div className={`logo-container ${isBlack ? 'blacked' : ''}`}>
            <img className="logo-image-small" src={appLogo} alt="IChat Pay Logo" />
            <span className={`company-name ${isBlack ? 'blacked' : ''}`}>{intl.formatMessage({ id: "ICHAT_PAY" })}</span>
          </div>
        </a>
        <ul className={`navigation-menu ${isBlack ? 'blacked' : ''}`}>
          <li><a href={isSubPage ? "/" : "#Home"}>{intl.formatMessage({ id: "HOME" })}</a></li>
          <li><a href={isSubPage ? "/" : "#ProductOverview"}>{intl.formatMessage({ id: "OVERVIEW" })}</a></li>
          <li><a href={isSubPage ? "/" : "#KeyFeatures"}>{intl.formatMessage({ id: "KEY_FEATURES" })}</a></li>
          <li><a href={isSubPage ? "/" : "#OurStory"}>{intl.formatMessage({ id: "OUR_STORY" })}</a></li>
          <li><a href={isSubPage ? "/" : "#Features"}>{intl.formatMessage({ id: "FEATURE" })}</a></li>
          <li><a href={isSubPage ? "/" : "#Solutions"}>{intl.formatMessage({ id: "SOLUTIONS" })}</a></li>
          <li><a href={isSubPage ? "/" : "#ContactUs"}>{intl.formatMessage({ id: "CONTACT" })}</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
