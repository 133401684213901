import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import './OurStory.less'; 
import { ConfigProvider } from "antd";
const FeaturesComponent = ({ refFeatures }: any) => {
    const intl = useIntl();
    const screenWidth = window.outerWidth;
    const featureHeading = useRef<HTMLDivElement>(null);
    const featuresDescription = useRef<HTMLDivElement>(null);
    const featuresDescription2 = useRef<HTMLDivElement>(null);
    const featuresCarousel = useRef<HTMLDivElement>(null);
    const headlineImage = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const imageObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate');
                        imageObserver.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        const textObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate');
                        textObserver.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        const currentHeadingRef = featureHeading.current;
        const currentDescriptionRef = featuresDescription.current;
        const currentDescriptionRef2 = featuresDescription2.current;
        const currentCarouselRef = featuresCarousel.current;
        const currentheadlineImageRef = headlineImage.current;
       
        if (currentHeadingRef) {
            textObserver.observe(currentHeadingRef);
        }

        if (currentDescriptionRef) {
            textObserver.observe(currentDescriptionRef);
        }

        if (currentDescriptionRef2) {
            textObserver.observe(currentDescriptionRef2);
        }

        if (currentCarouselRef) {
            imageObserver.observe(currentCarouselRef);
        }

        if(currentheadlineImageRef){
            imageObserver.observe(currentheadlineImageRef);
        }
        
        return () => {
            if (currentHeadingRef) {
                textObserver.unobserve(currentHeadingRef);
            }

            if (currentDescriptionRef) {
                textObserver.unobserve(currentDescriptionRef);
            }

            if (currentDescriptionRef2) {
                textObserver.unobserve(currentDescriptionRef2);
            }

            if (currentCarouselRef) {
                imageObserver.unobserve(currentCarouselRef);
            }

            if(currentheadlineImageRef){
                imageObserver.unobserve(currentheadlineImageRef);
            }
        };
    }, []);


    return (
        <ConfigProvider
            theme={{
                components: {
                    Carousel: {
                        arrowOffset: -1000,
                        arrowSize: (screenWidth <= 576) ? 20 : (screenWidth > 576 && screenWidth <= 1024) ? 30 : 40
                    }
                }
            }}
        >
            <div ref={refFeatures} className="features-container">
                <div className="feature-description-panel-1" ref={featuresDescription}>
                    <div className="our-story-description">
                    <h2>{intl.formatMessage({id: 'OUR_STORY'})}</h2>
                        <span>{intl.formatMessage({id: "OUR_STORY_DESC_1"})}</span>
                        <br/> <br/>
                        <span>{intl.formatMessage({id: "OUR_STORY_DESC_2"})}</span>
                    </div>
                    <div className="our-values-description">
                        <h2>{intl.formatMessage({ id: "FEATURES_RESOLUTION_HEADER" })}</h2>
                        <span className="emphasise-keywords">{intl.formatMessage({id: "OUR_VALUES_1"})}: </span>
                        <span>{intl.formatMessage({id: "OUR_VALUES_1_DESC"})}</span>
                        <br/> <br/>
                        <span className="emphasise-keywords">{intl.formatMessage({id: "OUR_VALUES_2"})}: </span>
                        <span>{intl.formatMessage({id: "OUR_VALUES_2_DESC"})}</span>
                        <br/> <br/>
                        <span className="emphasise-keywords">{intl.formatMessage({id: "OUR_VALUES_3"})}: </span>
                        <span>{intl.formatMessage({id: "OUR_VALUES_3_DESC"})}</span>
                    </div>
                </div>

                <div className="feature-description-panel-2" ref={featuresDescription2}>
                    <div className="our-journey-description">
                        <h2>{intl.formatMessage({id: 'FEATURES_ANNUAL_RETURN_HEADER'})}</h2>
                        <span>{intl.formatMessage({id: 'OUR_JOURNEY_DESC'})}</span>
                    </div>
                    <div className="our-mission-description">
                        <h2>{intl.formatMessage({ id: "FEATURES_TMS_HEADER" })}</h2>
                        <span>{intl.formatMessage({ id: "FEATURES_TMS_DESCRIPTION_1_2" })}</span>
                    </div>
                </div>
            </div>
      </ConfigProvider>

    )
};

export default FeaturesComponent;
