import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { registerUrl } from "../../content/HomeContent";
import appLogo from '../../assets/images/logo_collapsed.svg';
import LanguageSwitcher from '../language-switcher/LanguageSwitcher';
import heroBackground from '../../assets/images/hero-banner.png';
import './Hero.less';

const Hero = ({ refHome }: any) => {
    const intl = useIntl();
    const heroInnerContainerRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        const timer = setTimeout(() => {
            if (heroInnerContainerRef.current) {
                heroInnerContainerRef.current.classList.add('animate');
            }
        }, 100);

        return () => clearTimeout(timer);
    }, []);
        
    useEffect(() => {
        const backgroundImg = new Image();
        backgroundImg.src = heroBackground;
        backgroundImg.onload = () => {
            setIsLoading(false);
        };
    }, []);

    return (
        <div id="Home" ref={refHome} className={`hero-container ${isLoading ? 'loading' : 'loaded'}`}  >
            <div className="opacity-overlay" />
            <div ref={heroInnerContainerRef} className="hero-inner-container">
                    <h1 className="heading">
                        <span className="quote1">{intl.formatMessage({ id: "ICHATPAY_SLOGAN_LINE1" })}</span>
                        <span className="slanted-background">&nbsp;{intl.formatMessage({ id: "ICHATPAY_SLOGAN_LINE2" })}&nbsp;&nbsp;</span>
                    </h1>
            </div>
        </div>
    );
};

export default Hero;
