import { useRef, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import "./FeatureHighlights.less";
import { Card, Skeleton } from "antd";

const FeatureHighlights = () => {
    const intl = useIntl();
    const featureHighlightRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("fade-in");
                    }
                });
            },
            { threshold: 0.1 }
        );

        const currentFeatureHighlightRef = featureHighlightRef.current;

        if(currentFeatureHighlightRef) {
            observer.observe(currentFeatureHighlightRef)
        }

        return () => {
            if(currentFeatureHighlightRef) {
                observer.unobserve(currentFeatureHighlightRef)
            }
        }

    }, []);


    return (
        <div className="feature-highlights-container"  ref={featureHighlightRef}>
            <h1 className="feature-highlights-header">
                <span className="feature-highlights-header-1">{intl.formatMessage({ id: "FEATURES_HEADING_LINE01" })}</span>
                <span className="feature-highlights-header-2">{intl.formatMessage({ id: "FEATURES_HEADING_LINE2" })}</span>
            </h1>
            <div className="feature-highlights-content" >
                <Card className="feature-highlight-card" title={intl.formatMessage({id: "FEATURE_HIGHLIGHT_1"})} bordered={false} >          
                    <p className="feature-highlight-desc">  
                        {intl.formatMessage({id:"FEATURE_HIGHLIGHT_1_DESC"})}                      
                    </p>
                </Card>

                <Card className="feature-highlight-card" title={intl.formatMessage({id: "FEATURE_HIGHLIGHT_2"})} bordered={false} >          
                    <p className="feature-highlight-desc">  
                        {intl.formatMessage({id:"FEATURE_HIGHLIGHT_2_DESC"})}                      
                    </p>
                </Card>

                <Card className="feature-highlight-card" title={intl.formatMessage({id: "FEATURE_HIGHLIGHT_3"})} bordered={false} >          
                    <p className="feature-highlight-desc">  
                        {intl.formatMessage({id:"FEATURE_HIGHLIGHT_3_DESC"})}                      
                    </p>
                </Card>

                <Card className="feature-highlight-card" title={intl.formatMessage({id: "FEATURE_HIGHLIGHT_4"})} bordered={false} >          
                    <p className="feature-highlight-desc">  
                        {intl.formatMessage({id:"FEATURE_HIGHLIGHT_4_DESC"})}                      
                    </p>
                </Card>

                <Card className="feature-highlight-card" title={intl.formatMessage({id: "FEATURE_HIGHLIGHT_5"})} bordered={false} >          
                    <p className="feature-highlight-desc">  
                        {intl.formatMessage({id:"FEATURE_HIGHLIGHT_5_DESC"})}                      
                    </p>
                </Card>

                <Card className="feature-highlight-card" title={intl.formatMessage({id: "FEATURE_HIGHLIGHT_6"})} bordered={false} >          
                    <p className="feature-highlight-desc">  
                        {intl.formatMessage({id:"FEATURE_HIGHLIGHT_6_DESC"})}                      
                    </p>
                </Card>
                
                <Card className="feature-highlight-card" title={intl.formatMessage({id: "FEATURE_HIGHLIGHT_7"})} bordered={false} >          
                    <p className="feature-highlight-desc">  
                        {intl.formatMessage({id:"FEATURE_HIGHLIGHT_7_DESC"})}                      
                    </p>
                </Card>
                
            </div>
        </div>
    );
};

export default FeatureHighlights;
