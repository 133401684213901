import React, { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import OurStory from "../../assets/images/content-image-1.png";
import "./OurStory.less";

const OurStoryHeadline = ({ refOurStory }: any) => {
    const intl = useIntl();
    const ourStoryImageColumnRef = useRef<HTMLDivElement>(null);
    const ourStoryTextColumnRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const imageObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate');
                        imageObserver.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        const textObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate');
                        textObserver.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        const currentImageRef = ourStoryImageColumnRef.current;
        const currentTextRef = ourStoryTextColumnRef.current;

        if (currentImageRef) {
            imageObserver.observe(currentImageRef);
        }

        if (currentTextRef) {
            textObserver.observe(currentTextRef);
        }

        return () => {
            if (currentImageRef) {
                imageObserver.unobserve(currentImageRef);
            }
            if (currentTextRef) {
                textObserver.unobserve(currentTextRef);
            }
        };
    }, []);

    return (
        <div id="OurStory" ref={refOurStory} className="our-story-headline-container">

            <div ref={ourStoryTextColumnRef} className="our-story-text-column">
                <h2 className="our-story-heading">{intl.formatMessage({ id: "ABOUT_US_HEADLINE" })}</h2>
                <p className="our-story-headline-description">{intl.formatMessage({ id: "ABOUT_US_SUBHEADLINE" })}</p>
            </div>
            <div ref={ourStoryImageColumnRef} className="our-story-headline-image-column">
                <img src={OurStory} alt="Our Story Image" />
            </div>
        </div>
    );
};

export default OurStoryHeadline;
