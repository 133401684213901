import React, { useEffect, useState } from 'react';
import whatsappLogo from '../../assets/images/whatsapp.svg';
import bizmateLogo from '../../assets/images/logo_collapsed.svg';
import sendIcon from '../../assets/images/sent.png';
import closeIcon from '../../assets/images/x.svg';
import { salesContactLink } from '../../content/HomeContent';
import { useIntl } from 'react-intl';
import { getWhatsappTemplate } from "../../content/WhatsappMessageContent";
import './WhatsappButton.less';

const WhatsappButton = ({ isScrollButtonVisible }:any) => {
    const intl = useIntl();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [closing, setClosing] = useState(false);
    const [customMessage, setCustomMessage] = useState('');
    const whatsappTemplate = getWhatsappTemplate(intl);

    const toggleModal = () => {
        if (modalIsOpen) {
            setClosing(true);
            setTimeout(() => {
                setModalIsOpen(false);
                setClosing(false);
            }, 300); // Duration of the close animation
        } else {
            setModalIsOpen(true);
        }
    };

    const sendMessage = () => {
        const whatsappUrl = `${salesContactLink}&text=${encodeURIComponent(customMessage)}`;
        window.open(whatsappUrl, '_blank');
        setModalIsOpen(false);
    };

    // Clean up message when modal is close
    useEffect(() => {
        if (!modalIsOpen){
            setCustomMessage('')
        }
    }, [modalIsOpen]);

    return (
        <>
            <div className="whatsapp-button-container" style={{ bottom: isScrollButtonVisible ? '90px' : '20px' }} onClick={toggleModal}>
                <i className="whatsapp-button"><img src={whatsappLogo} alt="WhatsApp" /></i>
            </div>
            {modalIsOpen && (
                <div className={`whatsapp-chat-modal ${closing ? 'closing' : ''}`} style={{ bottom: isScrollButtonVisible ? '160px' : '90px' }}>
                    <div className="whatsapp-chat-modal-content">
                        <div className="whatsapp-chat-modal-header">
                            <div className="whatsapp-chat-modal-logo">
                                <img src={bizmateLogo} alt="WhatsApp" />
                            </div>
                            <div className="whatsapp-chat-modal-title">
                                <h2>{intl.formatMessage({ id: "ICHAT_PAY" })}</h2>
                                <span>{intl.formatMessage({ id: "WHATSAPP_REPLY_TIME" })}</span>
                            </div>
                            <button className="close-button" onClick={toggleModal}><img src={closeIcon} alt="close" /></button>
                        </div>
                        <div className="whatsapp-chat-modal-body">
                            <div className="chat-message tri-right left-top">
                                <div className="chat-bubble">
                                    <span>{intl.formatMessage({ id: "WHATSAPP_CHAT_LINE_1" })}</span>
                                    <span>{intl.formatMessage({ id: "WHATSAPP_CHAT_LINE_2" })}</span>
                                </div>
                                <span className="chat-time">{intl.formatMessage({ id: "WHATSAPP_CHAT_TIME" })}</span>
                            </div>
                            <div className="topic-buttons">
                                {whatsappTemplate.map((template, index) => (
                                    <button onClick={() => setCustomMessage(template.message)} key={index}>{template.message}</button>
                                ))}
                            </div>
                        </div>
                        <div className="whatsapp-chat-modal-footer">
                            <input 
                                value={customMessage} 
                                onChange={(e) => setCustomMessage(e.target.value)}
                                placeholder={intl.formatMessage({ id: "WHATSAPP_CHAT_PLACEHOLDER" })}
                            />
                            <button className="send-button" onClick={sendMessage}>
                                <i className="send-icon"><img src={sendIcon} alt="Sent"/></i>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default WhatsappButton;
