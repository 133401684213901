import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { getBenefitsContent } from "../../content/BenefitsContent";
import './KeyFeatures.less'; 
import { Skeleton } from "antd";

const KeyFeaturesComponent = () => {
    const intl = useIntl();
    const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
    const BenefitsContent = getBenefitsContent(intl);
    const [imageLoading, setImageLoading] = useState<boolean[]>(new Array(BenefitsContent.length).fill(true));

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible');
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                threshold: 0.1,
            }
        );

        cardRefs.current.forEach((ref) => {
            if (ref) {
                observer.observe(ref);
            }
        });

        return () => {
            observer.disconnect();
        };
    }, [BenefitsContent]);

    const handleImageLoad = (index: number) => {
        setImageLoading(prev => {
            const newState = [...prev];
            newState[index] = false;
            return newState;
        });
    };

    return (
        <div id="KeyFeatures" className="benefits-container">
            <h1 className="benefits-heading">
                <span className="benefits-heading-1">{intl.formatMessage({ id: "BENEFITS_HEADING_LINE1" })}</span>
                <span className="benefits-heading-2">{intl.formatMessage({ id: "BENEFITS_HEADING_LINE2" })}</span>
            </h1>
            <div className="vertical-spacer" />
            <div className="benefits-card-container">
                {BenefitsContent.map((card, i) => (
                    <div className="benefits-column" key={i} ref={(el) => (cardRefs.current[i] = el)}>
                        <div className="benefits-card">
                            <div className="benefits-card-inner">
                                <div className="benefits-card-front">
                                    <div className="benefits-image-container">
                                        {imageLoading[i] && <Skeleton.Avatar active={true} size={"large"} />}
                                        <img
                                            src={card.imageSrc}
                                            alt={card.title}
                                            onLoad={() => handleImageLoad(i)}
                                            onError={() => handleImageLoad(i)}
                                            style={{ display: imageLoading[i] ? 'none' : 'block' }}
                                        />
                                    </div>
                                    <div className="benefits-text-container">
                                        <h3 className="benefits-text-container-title">{card.title}</h3>
                                    </div>
                                </div>
                                <div className="benefits-card-back">
                                    <p className="benefits-text-container-description">{card.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default KeyFeaturesComponent;
