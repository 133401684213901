import React, { useState } from "react";
import { useIntl } from "react-intl";
import { loginUrl } from "../../content/HomeContent";
import LanguageSwitcher from "../language-switcher/LanguageSwitcher";
import "./HeaderCollapse.less";

const FullPageModal = ({ isOpen, onClose, isSubPage }: any) => {
  const intl = useIntl();

  return (
    <div className={`header-collapse-modal ${isOpen ? "active" : ""}`}>
      <nav className="header-collapse-modal-nav">
        <ul>
          <li>
            <a href={isSubPage ? "/" : "#Home"} onClick={onClose}>
              {intl.formatMessage({ id: "HOME" })}
            </a>
          </li>
          <li>
            <a href={isSubPage ? "/" : "#ProductOverview"} onClick={onClose}>
              {intl.formatMessage({ id: "OVERVIEW" })}
            </a>
          </li>
          <li>
            <a href={isSubPage ? "/" : "#KeyFeatures"} onClick={onClose}>
              {intl.formatMessage({ id: "KEY_FEATURES" })}
            </a>
          </li>
          <li>
            <a href={isSubPage ? "/" : "#OurStory"} onClick={onClose}>
              {intl.formatMessage({ id: "OUR_STORY" })}
            </a>
          </li>
          <li>
            <a href={isSubPage ? "/" : "#Features"} onClick={onClose}>
              {intl.formatMessage({ id: "FEATURE" })}
            </a>
          </li>
          <li>
            <a href={isSubPage ? "/" : "#Solutions"} onClick={onClose}>
              {intl.formatMessage({ id: "SOLUTIONS" })}
            </a>
          </li>
          <li>
            <a href={isSubPage ? "/" : "#ContactUs"} onClick={onClose}>
              {intl.formatMessage({ id: "CONTACT" })}
            </a>
          </li>

        </ul>
      </nav>
    </div>
  );
};

const CircleButton = ({ onClick, isOpen }: any) => (
  <button
    className={`menu-circle-button ${isOpen ? "close" : ""}`}
    onClick={onClick}
  >
    <span className={`bar ${isOpen ? "close" : ""}`}></span>
    <span className={`bar ${isOpen ? "close" : ""}`}></span>
    <span className={`bar ${isOpen ? "close" : ""}`}></span>
  </button>
);

const Header = ({ isSubPage }: any) => {
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);

  return (
    <div className="header-collapse">
      <CircleButton onClick={toggleModal} isOpen={modalOpen} />
      <FullPageModal
        isOpen={modalOpen}
        onClose={toggleModal}
        isSubPage={isSubPage}
      />
    </div>
  );
};

export default Header;
