import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import "./FeaturesHeadLine.less";

const FeaturesHeadLine = () => {
    const intl = useIntl();
    const featureImageRef = useRef<HTMLDivElement>(null);
    const featureTextColumnRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const imageObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate');
                        imageObserver.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        const textObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate');
                        textObserver.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        const currentImageRef = featureImageRef.current;
        const currentTextRef = featureTextColumnRef.current;

        if (currentImageRef) {
            imageObserver.observe(currentImageRef);
        }

        if (currentTextRef) {
            textObserver.observe(currentTextRef);
        }

        return () => {
            if (currentImageRef) {
                imageObserver.unobserve(currentImageRef);
            }
            if (currentTextRef) {
                textObserver.unobserve(currentTextRef);
            }
        };
    }, []);

    return (
        <div id="Features">
            <div ref={featureImageRef} className="features-headline-container">
                <div ref={featureTextColumnRef} className="features-headline-text-column">
                    <h2 className="features-headline-heading">{intl.formatMessage({id:"FEATURE"})}</h2>
                    <p className="features-headline-description">{intl.formatMessage({ id: "FEATURES_HEADLINE" })}</p>
                    <p className="features-headline-description">{intl.formatMessage({ id: "FEATURES_SUB_HEADLINE" })}</p>
                </div>
            </div>
        </div>
    );
};

export default FeaturesHeadLine;
