import { useEffect, useRef, useState } from "react";
import { useLayout } from "../hooks/LayoutContext";

import Hero from "../components/hero/Hero";
import Header from "../components/header/Header";
import OurStoryHeadline from "../components/our-story/OurStoryHeadlines";
import Footer from "../components/footer/Footer";
import ContactUs from "../components/contact-us/ContactUs";
import FeatureHighlights from "../components/feature-highlights/FeatureHighlights";
import ProductOverview from "../components/product-overview/ProductOverview";
import FeaturesHeadLine from "../components/features-headline/FeaturesHeadLine";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import KeyFeaturesComponent from "../components/key-features/KeyFeatures";
import OurStory from "../components/our-story/OurStory";
import Solutions from "../components/solutions/Solutions";
import HeaderCollapse from "../components/header/HeaderCollapse";

const Content = () => {
    const { layoutState } = useLayout();
    const [black, setBlack] = useState<boolean>(false);
    const [isScrollButtonVisible, setIsScrollButtonVisible] = useState(false);
    const refHero = useRef<any>(null);
    const refFeatures = useRef<any>(null);
    const intl = useIntl();

    const handleScroll = () => {
        if (refHero.current) {
            const heroHeight = refHero.current.offsetHeight;
            if (window.scrollY > heroHeight - 30) {
                setBlack(true);
            } else {
                setBlack(false);
            }
        }
    };

    useEffect(() => {
        if (layoutState.dimension.width > 768) {
            window.addEventListener('scroll', handleScroll);
        }
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [layoutState.dimension.width]); 

    return (
        <>
            <Helmet>
                <title>{intl.formatMessage({id: 'HELMET_DEFAULT_TITLE'})}</title>
                <meta name="description" content={`${intl.formatMessage({id: 'ICHATPAY_SLOGAN_LINE1'})} ${intl.formatMessage({id: 'ICHATPAY_SLOGAN_LINE2'})}`} />
            </Helmet>

            <HeaderCollapse isSubPage={false}/>
            <Header isBlack={!black} refHero={refHero} isSubPage={false}/>
            <Hero refHome={refHero} />
            <ProductOverview />
            <KeyFeaturesComponent/>
            <OurStoryHeadline refOurStory={refFeatures} />
            <OurStory/>
            <FeaturesHeadLine />
            <FeatureHighlights />
            <Solutions/>
            <ContactUs />
            {/* <WhatsappButton isScrollButtonVisible={isScrollButtonVisible} /> */}
            {/* <ScrollToTopButton setIsScrollButtonVisible={setIsScrollButtonVisible} /> */}
            <Footer />
        </>
    )
};

export default Content;
