import { useIntl } from 'react-intl';
import ForBusiness from '../../assets/images/for-business.jpg';
import ForDevelopers from '../../assets/images/for-developers.jpg';
import ForIndividuals from '../../assets/images/for-individuals.jpg';
import {Skeleton} from "antd";
import './Solutions.less';
import { useState, useEffect, useRef } from 'react';

const Solutions = () => {
    const intl = useIntl();
    const solutionsRef =  useRef<HTMLDivElement>(null);
    const [imageLoading, setImageLoading] = useState({
        ForBusiness: true,
        ForDevelopers: true,
        ForIndividuals: true,
    });

    useEffect(() => {
        const items = document.querySelectorAll(".solutions-row, .solutions-row-reverse");

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("fade-in");
                    }
                });
            },
            { threshold: 0.1 }
        );

        const currentSolutionsRef = solutionsRef.current;

        if(currentSolutionsRef) {
            observer.observe(currentSolutionsRef);
        }

        items.forEach((item) => {
            observer.observe(item);
        });

        return () => {
            if(currentSolutionsRef) {
                observer.unobserve(currentSolutionsRef)
            }
            items.forEach((item) => {
                observer.unobserve(item);
            });
        };
    }, []);

    // UseEffect to check if all images are loaded
    useEffect(() => {
        const images = [
            ForBusiness,
            ForDevelopers,
            ForIndividuals,
        ];

        let loadedCount = 0;
        const checkAllImagesLoaded = () => {
            loadedCount++;
            if (loadedCount === images.length) {
                // All images are loaded
                setImageLoading({
                    ForBusiness: false,
                    ForDevelopers: false,
                    ForIndividuals: false,
                });
            }
        };

        images.forEach((src) => {
            const img = new Image();
            img.onload = checkAllImagesLoaded;
            img.src = src;
        });

        return () => {
            images.forEach((src) => {
                const img = new Image();
                img.onload = null;
            });
        };
    }, []);

    return(
        <div id="Solutions" className="solutions-container" ref={solutionsRef}>
            <div className="solutions-header-container">
                <div className="solutions-header-text">
                    <h2 className="solutions-headline">
                        {intl.formatMessage({id: "SOLUTIONS_HEADLINE"})}
                    </h2>
                    <span className="solutions-subheadline">
                        {intl.formatMessage({id: "SOLUTIONS_SUBHEADLINE"})}
                    </span>
                </div>
            </div>
            
            <div className="solutions-content-background">

            <div className='solutions-content'>
                    <div className='solutions-row-reverse'>
                        <div className='solutions-row-column text-column'>
                            <div className="solutions-item">
                                <h2>{intl.formatMessage({id: 'SOLUTIONS_FOR_BUSINESSES'})}</h2>
                                <span>{intl.formatMessage({id: 'SOLUTIONS_FOR_BUSINESSES_SUBHEADLINE'})}</span>
                                <ul>
                                    <li className='emphasise-item'>{intl.formatMessage({id: 'SOLUTIONS_FOR_BUSINESSES_ITEM_4'})}</li>
                                    <li>{intl.formatMessage({id: 'SOLUTIONS_FOR_BUSINESSES_ITEM_1'})}</li>
                                    <li>{intl.formatMessage({id: 'SOLUTIONS_FOR_BUSINESSES_ITEM_2'})}</li>
                                    <li>{intl.formatMessage({id: 'SOLUTIONS_FOR_BUSINESSES_ITEM_3'})}</li>
                                </ul>
                            </div>
                        </div>
                        <div className='solutions-row-column img-col'>
                            {
                                imageLoading.ForBusiness 
                                ? <Skeleton.Image active={true}  className='img-skeleton'/>
                                :<img src={ForBusiness} className='solutions-image' alt="Content image" />
                            }
                        </div>
                    </div>

                    <div className='solutions-row'>
                        <div className='solutions-row-column img-col'>
                        {
                                imageLoading.ForDevelopers 
                                ? <Skeleton.Image active={true}  className='img-skeleton'/>
                                :<img src={ForDevelopers} alt="Content image" />
                            }
                        </div>
                        <div className='solutions-row-column text-column'>
                            <h2>{intl.formatMessage({id: 'SOLUTIONS_FOR_DEVELOPERS'})}</h2>
                            <span>{intl.formatMessage({id: 'SOLUTIONS_FOR_DEVELOPERS_SUBHEADLINE'})}</span>
                            <ul>
                                <li>{intl.formatMessage({id: 'SOLUTIONS_FOR_DEVELOPERS_ITEM_1'})}</li>
                                <li>{intl.formatMessage({id: 'SOLUTIONS_FOR_DEVELOPERS_ITEM_2'})}</li>
                                <li>{intl.formatMessage({id: 'SOLUTIONS_FOR_DEVELOPERS_ITEM_3'})}</li>
                            </ul>
                        </div>
                    </div>
                    <div className='solutions-row-reverse'>
                        <div className='solutions-row-column text-column'>
                            <h2>{intl.formatMessage({id: 'SOLUTIONS_FOR_INDIVIDUALS'})}</h2>
                            <span>{intl.formatMessage({id: 'SOLUTIONS_FOR_INDIVIDUALS_SUBHEADLINE'})}</span>
                            <ul>
                                <li>{intl.formatMessage({id: 'SOLUTIONS_FOR_INDIVIDUALS_ITEM_1'})}</li>
                                <li>{intl.formatMessage({id: 'SOLUTIONS_FOR_INDIVIDUALS_ITEM_2'})}</li>
                                <li>{intl.formatMessage({id: 'SOLUTIONS_FOR_INDIVIDUALS_ITEM_3'})}</li>
                            </ul>
                        </div>
                        <div className='solutions-row-column img-col'>
                        {
                                imageLoading.ForIndividuals 
                                ? <Skeleton.Image active={true}  className='img-skeleton'/>
                                :<img src={ForIndividuals} alt="Content image" />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Solutions;