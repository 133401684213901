import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { contactNumber, salesContactLink, officeAddress, enquiryEmail } from "../../content/HomeContent";
import emailImage from "../../assets/images/email-illustration.svg";
import emailIcon from "../../assets/images/email-color.png";
import whasappIcon from "../../assets/images/whatsapp-color.png";
import locationIcon from "../../assets/images/location.png";
import "./ContactUs.less";
import { Button, Skeleton } from "antd";

const ContactUs = () => {
    const intl = useIntl();
    const contactUsContainerRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate');
                    }
                });
            },
            { threshold: 0.1 }
        );

        const currentRef = contactUsContainerRef.current;
        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    useEffect(() => {
        const backgroundImg = new Image();
        backgroundImg.src = emailImage;
        backgroundImg.onload = () => {
            setIsLoading(false);
        };
    }, []);

    return (
        <div id="ContactUs" className="contact-us-container" ref={contactUsContainerRef}>
            <div className="contact-us-image-column">
                { isLoading 
                   ? <Skeleton.Image active={true} className="contact-us-skeleton-image" />
                   : <img className="contact-us-image" src={emailImage} alt="contact-us-icon"/>
                }
            </div>
            <div className="contact-us-text-column">
                <div className="contact-us-text-content">
                    <div className="contact-us-subheading">{intl.formatMessage({ id: "CONTACT_US" })}</div>
                    <h1 className="contact-us-heading">
                        <>
                            {intl.formatMessage({ id: "CONTACT_US_HEADING_LINE1" })}
                            <span className="text-secondary">{intl.formatMessage({ id: "CONTACT_US_HEADING_LINE2" })}</span>
                            <br/>
                            {intl.formatMessage({ id: "CONTACT_US_HEADING_LINE3" })}
                        </>
                    </h1>
                    <div className="contact-us-description">
                        <div className="description-office-1-line">
                            <span><img src={locationIcon} alt="" /></span>
                            <span>{officeAddress}</span>
                        </div>
                        <div className="description-office-multi-line">
                            <span><img src={locationIcon} alt="" /></span>
                            {officeAddress.split("\n").map(function(item, idx) {
                                return (
                                    <span key={idx}>
                                        {item}
                                        <br/>
                                    </span>
                                );
                            })}
                        </div>

                        <div className="description-item">
                            <Button
                                type="default"
                                href={`mailto:${enquiryEmail}`}
                                className="email-button"
                            >
                                Email Us!
                            </Button>
                        </div>
                        {/* <div className="description-item">
                            <span><img src={whasappIcon} alt="" /></span>
                            <a href={salesContactLink} target="_blank" rel="noreferrer">{contactNumber}</a>
                        </div> */}
                    </div>

                    {/* <form className="contact-us-form" action="#" method="POST">
                        <input type="email" name="email" className="contact-us-email-input" placeholder={intl.formatMessage({ id: "CONTACT_US_PLACEHOLDER" })} />
                        <button type="submit" className="submit-btn">{intl.formatMessage({ id: "CONTACT_US" })}</button>
                    </form> */}
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
